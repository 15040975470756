export class HttpClass {
    SendForm (Endpoint: string, Content: string, Form: any) {
        const Button = Form.find('.submit');

        Button.attr('disabled', 'disabled').css('opacity', '0.6');

        $.ajax({
            url: Endpoint,
            data: Content,
            type: 'json',
            method: 'post',

            success: (data) => {
                console.log(data);
                $('.notifications').css('display', 'block').html(data);
            },
            error: (data) => {
                console.log(data);
                Button.delay(400).removeAttr('disabled').css('opacity', 1);
                $('.notifications').css('display', 'block').html();
            }
        });
    }
}