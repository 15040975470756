import { HttpClass } from './http';

$('.contact-form').submit(function(e) {
    e.preventDefault();
    const Data = $(this).serialize();
    const httpClass = new HttpClass();
    const Endpoint = $(this).attr('action');
    const Form = $(this);
    httpClass.SendForm(Endpoint, Data, Form);
});
